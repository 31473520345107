import React from 'react';
import { Link } from 'react-router-dom';

const goOrbitUrl = process.env.REACT_APP_GO_ORBIT_URL

export const FooterSection = ({ footerLight, style, footerGradient }) => {


  
  

  const smoothScroll = (ele) => {
    ele.scrollIntoView({
      behavior: 'smooth',
    })
  }



  const scrollToHome = () => {
    let home = document.getElementById('home')
    smoothScroll(home)
  }

  return (
    <>
      <footer className='footer-section'>
        <div
          className={`footer-top ${footerLight ? 'footer-light' : 'bg-dark'} ${
            footerGradient ? 'bg-gradient' : ''
          }  text-white ptb-120`}
          style={style}
        >
          <div className='container'>
            <div className='row justify-content-between'>
              <div className='col-md-8 col-lg-4 mb-md-4 mb-lg-0'>
                <div className='footer-single-col'>
                  <div className='footer-single-col mb-4'>
                    <img
                      src='assets/images/go-logo-white.png'
                      alt='logo'
                      className='img-fluid logo-white'
                    />
                    <img
                      src='assets/images/go-logo.png'
                      alt='logo'
                      className='img-fluid logo-color'
                    />
                  </div>
                  <p>
                    GoOrbit Technologies Private Limited, Noida, India
                  </p>
                
                  
                </div>
              </div>
              <div className='col-md-12 col-lg-7 mt-4 mt-md-0 mt-lg-0'>
                <div className='row'>
                <div className='col-md-4 col-lg-4 mt-4 mt-md-0 mt-lg-0'>
                    <div className='footer-single-col'>
                      <h3>Company</h3>
                      <ul className='list-unstyled footer-nav-list mb-lg-0'>
                        <li>
                          <a href={goOrbitUrl}>Go Orbit</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* <div className='col-md-4 col-lg-4 mt-4 mt-md-0 mt-lg-0'>
                    <div className='footer-single-col'>
                      <h3>Solutions</h3>
                      <ul className='list-unstyled footer-nav-list mb-lg-0'>
                        <li>
                          <Link to='/' className='text-decoration-none'>
                            GYM
                          </Link>
                        </li>
                        <li>
                          <Link to='/' className='text-decoration-none'>
                            Schools
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div> */}
                  <div className='col-md-4 col-lg-4 mt-4 mt-md-0 mt-lg-0'>
                    <div className='footer-single-col'>
                      <h3>Resources</h3>
                      <ul className='list-unstyled footer-nav-list mb-lg-0'>
                        <li>
                          <Link to='/' className='text-decoration-none'>
                            Support
                          </Link>
                        </li>
                        {/* <li>
                          <Link to='/' className='text-decoration-none'>
                            Help
                          </Link>
                        </li>
                        <li>
                          <Link to='/' className='text-decoration-none'>
                            API Documentation
                          </Link>
                        </li>
                        <li>
                          <Link to='/' className='text-decoration-none'>
                            What's New
                          </Link>
                        </li>
                        <li>
                          <Link to='/' className='text-decoration-none'>
                            Discussion Forum
                          </Link>
                        </li> */}
                        <li>
                          <Link to='/' className='text-decoration-none'>
                            Blog
                          </Link>
                        </li>

                        <li>
                          <Link to='/Promo' className='text-decoration-none'>
                            Promo
                          </Link>
                        </li>

                      </ul>
                    </div>
                  </div>

                  <div className='col-md-4 col-lg-4 mt-4 mt-md-0 mt-lg-0'>
                    <div className='footer-single-col'>
                      <h3>Policies</h3>
                      <ul className='list-unstyled footer-nav-list mb-lg-0'>
                        <li>
                          <Link to='/contactus' className='text-decoration-none' onClick={scrollToHome}>
                            Contact Us
                          </Link>
                        </li>
                        <li>
                          <Link to='/termsandcondition' className='text-decoration-none' onClick={scrollToHome}>
                            Terms and Conditions
                          </Link>
                        </li>
                        
                        
                        <li>
                          <Link to='/privacypolicy' className='text-decoration-none' onClick={scrollToHome}>
                            Privacy Policy
                          </Link>
                        </li>
                        <li>
                          <Link to='/shipping' className='text-decoration-none' onClick={scrollToHome}>
                            Shipping & Delivery Policy
                          </Link>
                        </li>

                        <li>
                          <Link to='/cancelandrefund' className='text-decoration-none' onClick={scrollToHome}>
                            Cancellation & Refund Policy
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className={`footer-bottom ${
            footerLight ? 'footer-light' : 'bg-dark'
          } ${footerGradient ? 'bg-gradient' : ''} text-white py-4`}
        >
          <div className='container'>
            <div className='row justify-content-between align-items-center'>
              <div className='col-md-7 col-lg-7'>
                <div className='copyright-text'>
                  <p className='mb-lg-0 mb-md-0'>
                    &copy; 2023 GoOrbit Technologies Private Limited or its affiliates. All rights reserved
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};
