import React from 'react';
import { HeaderSection } from './HeaderSection';
import { FooterSection } from './FooterSection';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const apiUrl = process.env.REACT_APP_LEADS_API_END_POINT;

function Promo1() {
  const submitForm = (_e) => {
    const responseBody = {};
    _e.preventDefault();
    const form = _e.currentTarget;
    const formData = new FormData(form);
    formData.forEach((value, property) => (responseBody[property] = value));
    responseBody.request_type = 'contact';

    fetch(apiUrl, {
      method: 'POST',
      body: JSON.stringify(responseBody),
      mode: 'no-cors',
    })
      .then((response) => {
        if (response.status === 200 || response.status === 0) {
          toast.success('Request Successful', {
            hideProgressBar: true,
            autoClose: 2000,
          });
          form.reset();
        } else {
          toast.error('Something Went Wrong! Please try again', {
            hideProgressBar: true,
            autoClose: 2000,
          });
        }
      })
      .catch(() => {
        toast.error('Something Went Wrong! Please try again', {
          hideProgressBar: true,
          autoClose: 2000,
        });
      });
  };

  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '30px',
      borderRadius: '8px',
      width: '85%',
      margin: '0 auto',
      minHeight: '100vh',
      justifyContent: 'space-between',
    },
    video: {
      width: '380px',
      height: 'auto',
      borderRadius: '8px',
      marginBottom: '30px',
      marginTop: '75px',
    },
    footer: {
      width: '1550px',
      padding: '20px 0',
      backgroundColor: '#f1f1f1',
      textAlign: 'center',
      marginTop: '50px',
    },
  };

  return (
    <div style={styles.container}>
      <HeaderSection />

      {/* Video Section */}
      <video style={styles.video} controls>
        <source
          src={`${process.env.PUBLIC_URL}/assets/images/BillorbitUpiPaylinkGym.mp4`}
          type='video/mp4'
        />
      </video>

      {/* Text Section  */}
     <div style={{ textAlign: 'center', marginBottom: '30px',marginTop: '60px'}}>
      {/* Schedule Free Demo */}
      <h2 style={{ color: 'blue', fontSize: '24px', fontWeight: 'bold', marginBottom: '10px' }}>
       Schedule Free Demo
      </h2>

      {/* Request Today */}
      <h3 style={{ fontSize: '30px', fontWeight: 'bold', marginBottom: '15px' }}>
        Request Today! Get Your Free Demo
      </h3>

      {/* Informational Paragraph */}
     <p style={{ fontSize: '16px', lineHeight: '1.6', color: '#333', maxWidth: '800px', margin: '0 auto' }}>
       If you don't have an efficient Tution Fee Management system, 
       you will be wasting lots of time tracking and managing Student's Dues.
       It's time to give your Institute a smarter way to track and manage Student's Dues. 
       By investing in the right Recurring Fee Management platform, 
       you'll increase control over all your Fee Management needs while responding more quickly to changes in dynamic environment.
     </p>
     </div>

      {/* Form Heading */}
      <h1
        style={{
          fontSize: '28px',
          color: '#2b2a2a',
          marginBottom: '30px',
          marginTop: '30px',
          textAlign: 'left',
          width: '100%',
        }}
      >
        Leave Your Details For Us
      </h1>

      {/* Form and Image Container */}
      <div
        style={{
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        {/* Form Section */}
        <div style={{ width: '60%' }}>
          <div className='form-notification'>
            <ToastContainer theme='colored' />
          </div>
          <form onSubmit={submitForm} className='register-form'>
            <div className='row'>
              <div className='col-sm-6'>
                <label htmlFor='name' className='mb-1'>
                  Name <span className='text-danger'>*</span>
                </label>
                <div className='input-group mb-3'>
                  <input
                    type='text'
                    className='form-control'
                    id='name'
                    name='name'
                    required
                    placeholder='Name'
                    aria-label='Name'
                  />
                </div>
              </div>
              <div className='col-sm-6'>
                <label htmlFor='phone' className='mb-1'>
                  Phone <span className='text-danger'>*</span>
                </label>
                <div className='input-group mb-3'>
                  <input
                    type='text'
                    className='form-control'
                    id='phone'
                    name='phone'
                    pattern='^[0-9]{10}$'
                    title='Please enter a valid phone number!'
                    required
                    placeholder='Phone'
                    aria-label='Phone'
                  />
                </div>
              </div>
              <div className='col-sm-6'>
                <label htmlFor='email' className='mb-1'>
                  Email
                </label>
                <div className='input-group mb-3'>
                  <input
                    type='email'
                    className='form-control'
                    id='email'
                    name='email'
                    placeholder='Email'
                    aria-label='Email'
                  />
                </div>
              </div>
              <div className='col-sm-6'>
                <label htmlFor='name_business_entity' className='mb-1'>
                  Business Entity Name
                </label>
                <div className='input-group mb-3'>
                  <input
                    type='text'
                    name='name_business_entity'
                    className='form-control'
                    id='name_business_entity'
                    placeholder='Business Entity Name'
                    aria-label='Business Entity Name'
                  />
                </div>
              </div>
              <div className='col-12'>
                <label htmlFor='message' className='mb-1'>
                  Message <span className='text-danger'>*</span>
                </label>
                <div className='input-group mb-3'>
                  <textarea
                    className='form-control'
                    id='message'
                    name='message'
                    required
                    placeholder='How can we help you?'
                    style={{ height: '120px' }}
                  ></textarea>
                </div>
              </div>
            </div>
            <button type='submit' className='btn btn-primary mt-4'>
              Get in Touch
            </button>
          </form>
        </div>

        {/* Image Section */}
        <div style={{ width: '50%', marginLeft: '30px' }}>
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/contact-us-img-2.svg`}
            alt='Sample'
            style={{
              width: '100%',
              borderRadius: '10px',
              // boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            }}
          />
        </div>
      </div>

      {/* Footer Section */}
      <div style={styles.footer}>
        <FooterSection />
      </div>
    </div>
  );
}

export default Promo1;